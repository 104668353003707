import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';

import ImageProviders from './ImageProviders';
import { getAspectRatio, getUrl } from './utils';
import Uploader from '../../components/Uploader';
import { Card } from '@blueprintjs/core';

const StyledCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  .card-title {
    display: block;
    flex-grow: 1;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.5;
    }
  }
`;

const ImagePreview = styled.div`
  background-image: ${({ url }) => `url(${url})` || 'none'};
  padding-top: ${({ aspectRatio = 56 }) => aspectRatio}%;
  background-size: cover;
  background-position: center;
  margin-bottom: 12px;
  width: 100%;
  background-color: #efefef;
`;

const EditBlockImage = ({ block = {}, onChange, onRemove }) => {
  const { options = {} } = block;
  return (
    <Card style={{ margin: 8 }} interactive>
      <StyledCloseButtonContainer>
        <div className="card-title">Image</div>
        <IoMdClose onClick={() => onRemove(block.id)} />
      </StyledCloseButtonContainer>
      <ImagePreview
        url={getUrl(options)}
        aspectRatio={getAspectRatio(options)}
      />
      <Uploader
        onChange={async uploadPromise => {
          const upload = await uploadPromise;
          const { uuid, originalImageInfo } = upload;
          const { height, width } = originalImageInfo;
          onChange(block.id, {
            ...options,
            height,
            width,
            provider: ImageProviders.Uploadcare,
            path: uuid,
          });
        }}
      />
    </Card>
  );
};

export default EditBlockImage;
