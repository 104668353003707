import reset from '../styles/reset'
import base from '../styles/base'

/*
<html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      ${reset}
      ${base}
      ${styles}
    </style>
  </head>
  <body>  
    ${children}
  </body>
</html>
*/

export default ({ children = '', styles = '' }) =>
  `<html><head><meta name="viewport" content="width=device-width, initial-scale=1"><style type="text/css">${reset}${base}${styles}</style></head><body>${children}</body></html>`
