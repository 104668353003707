import React from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

import { IoMdClose } from 'react-icons/io';
import { Card } from '@blueprintjs/core';

const StyledCloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  .card-title {
    display: block;
    flex-grow: 1;
    margin-bottom: 0;
  }

  svg {
    cursor: pointer;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.5;
    }
  }
`;

const EditBlockLink = ({ block, onChange, onRemove }) => {
  const { options = {} } = block;

  const [label, setLabel] = React.useState(options.label || 'Button Label');
  const [url, setUrl] = React.useState(options.url || '');

  return (
    <Card style={{ margin: 8 }} interactive>
      <StyledCloseButtonContainer>
        <div className="card-title">Link Button</div>
        <IoMdClose onClick={() => onRemove(block.id)} />
      </StyledCloseButtonContainer>
      <Form.Group>
        <Form.Control
          value={label}
          placeholder="Label"
          onChange={e => setLabel(e.target.value)}
          onBlur={() => onChange(block.id, { ...options, label })}
        />
        <Form.Control
          value={url}
          placeholder="https://website.com"
          onChange={e => setUrl(e.target.value)}
          onBlur={() => onChange(block.id, { ...options, url })}
        />
      </Form.Group>
    </Card>
  );
};

export default EditBlockLink;
