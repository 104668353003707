import EditBlockImage from './EditBlockImage';
import { getUrl, getAspectRatio } from './utils';

export default {
  Editor: EditBlockImage,
  renderHtml: ({ path, height, width, provider, zoomable } = {}) => {
    const previewUrl = getUrl({ path, provider });
    const aspectRatio = getAspectRatio({ height, width });
    const imagePreview = ({ children = '', onclick = '() => {}' } = {}) => `
      <div
        style="
          background-image: url(${previewUrl});
          background-size: cover;
          background-position: center;
          margin-bottom: 12px;
          width: 100%;
          background-color: #efefef;
          padding-top: ${aspectRatio}%;
        "
        class="zoomable-image"
        >
        ${children}
      </div>
    `;

    if (!zoomable) return imagePreview();

    const zoomUrl = getUrl({ path, provider, maxWidth: 1200 });
    const zoomPreview = `
      <div
        class="zoom-preview"
        style="
          position: fixed;
          top: 0;
          left: 0;
          overflow: scroll;
          background: #000000a6;
          height: 100vh;
          width: 100vw;
          display: none;
        "
      >
        <img src="${zoomUrl}" style="width:auto;" />
        <div
          class="zoom-close"
          style="
            transform: rotate(45deg);
            position: fixed;
            top: 24px;
            right: 24px;
            font-size: 45px;
            color: white;
            background: #22ba5b;
            height: 45px;
            width: 45px;
            text-align: center;
            line-height: 36px;
            border-radius: 50%;
          "
        >+</div>
      </div>
    `;

    return imagePreview({
      onclick: `$('.zoom-preview', this).show()`,
      children: zoomPreview,
    });
  },
};
