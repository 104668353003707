import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import {
  IoIosCall,
  IoIosCodeWorking,
  IoIosImages,
  IoIosLink,
  IoIosQuote,
  IoLogoVimeo,
  IoLogoYoutube,
} from 'react-icons/io';
import Helmet from 'react-helmet';
import { Base64 } from 'js-base64';
import { AuthenticatedContent } from '@humancollective/human-dashboard';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import {
  getCampaignData,
  getLocation,
  saveCampaignData,
  saveLocation,
} from '../utilities/firebase';
import render from '../utilities/render';
import blockDefs, { BuilderBlockType } from '../blocks';
import Navigation from '../components/Navigation';
import { ButtonGroup, Popover, Button, Menu } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Routes } from '../config';

const StyledCampaignBuilder = styled.div`
  display: flex;
  flex-grow: 1;

  .preview-pane {
    flex-grow: 1;
    background: #64b144;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    .preview-pane__phone {
      width: 360px;
      background: white;
      box-shadow: 0 10px 20px 5px #00000024;
      max-height: 80%;
      overflow: hidden;
      border-radius: 20px;
      flex-grow: 1;
    }
  }

  .builder-options {
    height: calc(100vh - 50px);
    width: 320px;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .builder-options__contents {
    flex-grow: 1;
    overflow-y: auto;
    background-color: whitesmoke;
  }

  .builder-options__controls {
    margin: 18px;
  }
`;

interface BuilderProps {
  location: any;
}

interface BuilderBlock {
  id: string;
  type: BuilderBlockType;
  options: any;
}

const addBlockOptions = [
  {
    type: BuilderBlockType.Image,
    icon: IoIosImages,
    label: 'Image',
  },
  {
    type: BuilderBlockType.Text,
    icon: IoIosQuote,
    label: 'Text',
  },
  {
    type: BuilderBlockType.Youtube,
    icon: IoLogoYoutube,
    label: 'YouTube',
  },
  {
    type: BuilderBlockType.Vimeo,
    icon: IoLogoVimeo,
    label: 'Vimeo',
  },
  {
    type: BuilderBlockType.Link,
    icon: IoIosLink,
    label: 'Link',
  },
  {
    type: BuilderBlockType.Phone,
    icon: IoIosCall,
    label: 'Call Button',
  },
  {
    type: BuilderBlockType.QrCode,
    icon: IoIosCodeWorking,
    label: 'QR Code',
  },
];

const Builder: React.FunctionComponent<BuilderProps> = ({ location }) => {
  const params = {
    location: '',
    campaign: '',
    screen: '',
    ...queryString.parse(location.search),
  };

  const [renderUrl, setRenderUrl] = React.useState('');
  const [renderedHtml, setRenderedHtml] = React.useState('');
  const [blocks, setBlocks] = React.useState([] as BuilderBlock[]);

  const changeBlock = (id: string, options: any) => {
    const nextBlocks = blocks.map(b =>
      b.id === id ? { ...b, options } : { ...b }
    );
    setBlocks(nextBlocks);
  };

  const addBlock = (type: BuilderBlockType) => {
    setBlocks([...blocks, { type, id: uuid(), options: {} }]);
  };

  const removeBlock = (id: string) => {
    const nextBlocks = blocks.filter(b => b.id !== id);
    setBlocks(nextBlocks);
  };

  const handleSave = async () => {
    try {
      if (params.campaign) {
        await saveCampaignData(params.campaign, {
          [params.screen]: { blocks, renderedHtml },
        });
        navigate(`${Routes.MAP}?type=campaign&id=${params.campaign}`);
      } else if (params.location) {
        await saveLocation(params.location, {
          [params.screen]: { blocks, renderedHtml },
        });
        navigate(`${Routes.MAP}?type=location&id=${params.location}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateRender = (nextBlocks: BuilderBlock[]) => {
    const children = nextBlocks
      .map(b => blockDefs[b.type].renderHtml(b.options))
      .join('');
    const nextRenderedHtml = render('unlocked', { children });
    setRenderedHtml(nextRenderedHtml);
    setRenderUrl(`data:text/html;base64,${Base64.encode(nextRenderedHtml)}`);
  };

  const handleDragEnd = (result: DropResult) => {
    const blockToMove = blocks.find(b => b.id === result.draggableId);
    const nextBlocks = blocks.filter(b => b.id !== result.draggableId);
    if (!result.destination || !blockToMove) return;
    nextBlocks.splice(result.destination.index, 0, blockToMove);
    setBlocks(nextBlocks);
  };

  const initialize = async () => {
    let data: any = {};
    if (params.campaign) data = await getCampaignData(params.campaign);
    if (params.location) data = await getLocation(params.location);
    const screen = data[params.screen] || {};
    setBlocks(screen.blocks || []);
  };

  React.useEffect(() => {
    handleUpdateRender(blocks);
  }, [blocks]);

  React.useEffect(() => {
    initialize();
  }, []);

  const addMenu = (
    <Menu>
      {addBlockOptions.map(({ type, icon, label }) => (
        <div
          key={type}
          className="bp3-menu-item bp3-popover-dismiss"
          onClick={() => addBlock(type)}
        >
          {icon({ className: 'bp3-icon' })}
          <div className="bp3-text-overflow-ellipsis bp3-fill">{label}</div>
        </div>
      ))}
    </Menu>
  );

  return (
    <AuthenticatedContent>
      <Helmet>
        <title>Campaign Builder - Seedz</title>
      </Helmet>
      <Navigation />
      <StyledCampaignBuilder>
        <div className="builder-options">
          <ButtonGroup className="builder-options__controls">
            <Popover content={addMenu}>
              <Button icon={IconNames.ADD} rightIcon={IconNames.CARET_DOWN}>
                Add Content
              </Button>
            </Popover>
            <Button icon={IconNames.FLOPPY_DISK} onClick={handleSave}>
              Save
            </Button>
          </ButtonGroup>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {droppableProvisions => (
                <div
                  className="builder-options__contents"
                  {...droppableProvisions.droppableProps}
                  ref={droppableProvisions.innerRef}
                >
                  {blocks.map((b, index) => {
                    const BlockEditor = blockDefs[b.type].Editor;
                    return (
                      <Draggable key={b.id} draggableId={b.id} index={index}>
                        {draggableProvisions => (
                          <div
                            ref={draggableProvisions.innerRef}
                            {...draggableProvisions.draggableProps}
                            {...draggableProvisions.dragHandleProps}
                            style={draggableProvisions.draggableProps.style}
                          >
                            <BlockEditor
                              block={b}
                              onChange={changeBlock}
                              onRemove={removeBlock}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {droppableProvisions.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div className="preview-pane">
          <div className="preview-pane__phone">
            <iframe
              title="preview-pane__iframe"
              src={renderUrl}
              frameBorder="0"
              height="100%"
              width="100%"
            />
          </div>
        </div>
      </StyledCampaignBuilder>
    </AuthenticatedContent>
  );
};

export default Builder;
