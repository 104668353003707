import ImageProviders from './ImageProviders';
import { IMAGE_URL } from '../../utilities/environment';

export const getUrl = ({
  path,
  provider = ImageProviders.ImgIX,
  maxWidth = 900,
}) => {
  if (provider === ImageProviders.ImgIX)
    return `https://${IMAGE_URL}${path}?w=${maxWidth}&auto=compress,format&bg=ffffff`;
  if (provider === ImageProviders.Uploadcare)
    return `https://ucarecdn.com/${path}/-/resize/${maxWidth}x/-/format/jpeg/-/quality/lighter/`;
  return '';
};

export const getAspectRatio = ({ width, height }) => (height / width) * 100; // aspect ratio
