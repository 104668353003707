import image from './image';
import text from './text';
import youtube from './youtube';
import vimeo from './vimeo';
import link from './link';
import phone from './phone';
import qrCode from './qrCode';

export enum BuilderBlockType {
  Image = 'image',
  Text = 'text',
  Youtube = 'youtube',
  Vimeo = 'vimeo',
  Link = 'link',
  Phone = 'phone',
  QrCode = 'qrCode',
}

export default {
  image,
  text,
  youtube,
  vimeo,
  link,
  phone,
  qrCode,
};
