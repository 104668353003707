import templateUnlocked from './templates/unlocked'
import templateRoot from './templates/root'

type TemplateArgs = { children: string; styles: string }
type Template = (args: TemplateArgs) => string

const templates: { [name: string]: Template } = {
  unlocked: templateUnlocked,
}

export default (
  templateName: string,
  { children = '', styles = '' } = {}
): string => {
  const template = templates[templateName] || templateRoot
  return template({ children, styles })
}
