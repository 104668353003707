import templateRoot from './root'

// img {
//   width: 100%;
// }
// .img-burger {
//   background-image: url('/images/burger.png');
//   background-color: #1f1511;
//   padding-top: 60%;
//   width: 100%;
//   background-size: cover;
// }
// h1 {
//   margin: 20px 20px 0;
//   text-align: center;
// }
// p {
//   margin: 20px 40px 0;
//   text-align: center;
//   line-height: 1.5;
// }

const styles =
  'h1,p{text-align:center}img{width:100%}.img-burger{background-image:url(/images/burger.png);background-color:#1f1511;padding-top:60%;width:100%;background-size:cover}h1{margin:20px 20px 0}p{margin:20px 40px 0;line-height:1.5}'

export default ({ children = '' }) => templateRoot({ styles, children })
