import EditBlockQRCode from './EditBlockQRCode';

const QRCODE_URL = 'https://unpkg.com/qrcodejs2@0.0.2/qrcode.js';

export default {
  Editor: EditBlockQRCode,
  renderHtml: ({ value = 'https://seedz.co' }: { value: string }) => {
    // By default, we render a QR code with the given value.
    // If window.setConfig() is called with a config cotaning a token,
    // then the QR code will display that token value.
    // tl;dr use window.setConfig({ token: 'blah' }) to override the QR code value
    return `
      <div style="padding:16px;width:calc(100% - 32px);" id="qrcode"></div>
      <script type="text/javascript" src="${QRCODE_URL}"></script>
      <script type="text/javascript">
          const qrcode = new QRCode(document.getElementById("qrcode"), {
            text: "${value}",
            colorDark : '#000000',
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.H
          });
        window.setConfig = (config) => {
          const codeURL = '${
            process.env.GATSBY_DASHBOARD_URL
          }/qr?token=' + config.token;
          qrcode.makeCode(codeURL);
        }
      </script>`;
  },
};
